<template>
    <div style="padding:50px;">
        <createForm
              :operations="formData.operations"
              :list="formData.fields"
              :inline="formData.inline"
              :labelWidth="formData.labelWidth"
              :labelPosition="formData.labelPosition"
              :size="formData.size"
              :formWidth="formData.formWidth"
            ></createForm>
        <tableView :tableParams="tableData"></tableView>
    </div>
</template>

<script>
import tableView from "@/components/tableView";
import createForm from "@/components/dynamicForm/createForm";
import {
  findFormDatas,
} from "@/api/form";
import {
  queryTableList,
} from "@/api/table";

export default {
    data(){
        return{
            formData:{},
            tableData:{}
        }
    },
    components:{
        createForm,tableView
    },
    created(){
        if(this.$route.params.formName && this.$route.params.tableName){
            console.log(this.$route.params);
            findFormDatas({
                formName:this.$route.params.formName
            }).then(res => {
                this.formData = res.data.data.list[0]
            })
            queryTableList({
                title:this.$route.params.tableName
            }).then(({data:res}) => {
                console.log(res);
                this.tableData = res.data.data.list[0]
            })
        }else{
            this.$message({
                message:'请重新选择表单和列表',
                type:'warning'
            })
            this.$router.replace('/')
        }
    }
}
</script>

<style>

</style>